import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

// Other
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IMaskModule } from 'angular-imask';

// Components
import { DialogComponent } from './shared/dialog/dialog.component';
import { UploadFileComponent } from './Pages/insured/Components/upload-file/upload-file.component';
import { LoginComponent } from './Pages/login/login.component';
import { InsuredComponent } from './Pages/insured/insured.component';
import { NotFoundComponent } from './Pages/not-found/not-found.component';
import { HttpClientModule } from '@angular/common/http';
import { CompletedSignComponent } from './Pages/insured/Components/completed-sign/completed-sign.component';
import { AppInitService } from './app-init.service';
import { DocumentsComponent } from './Pages/insured/Components/documents/documents.component';
import { DialogDwFileComponent } from './shared/dialog-dw-file/dialog-dw-file.component';
import { BrowserNotSupportComponent } from './Pages/browser-not-support/browser-not-support.component';
import { DialogUploadFileComponent } from './shared/dialog-upload-file/dialog-upload-file.component';

export function init_app(appInitService: AppInitService) {
  return () => appInitService.getEnvRoute();
}

@NgModule({
  declarations: [
    AppComponent,
    DialogComponent,
    UploadFileComponent,
    LoginComponent,
    InsuredComponent,
    NotFoundComponent,
    CompletedSignComponent,
    DocumentsComponent,
    DialogDwFileComponent,
    BrowserNotSupportComponent,
    DialogUploadFileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    NgxDropzoneModule,
    FontAwesomeModule,
    AngularMaterialModule,
    MatProgressSpinnerModule,
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
    IMaskModule
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
