declare var window: any;

export class DynamicEnvironment {
    public get environment() {
        return window.config.environment;
    }

    public get configMessage() {
        return window.config.message;
    }

    public get SessionDuration(){
        return window.config.environmentUrl.SESSION_DURATION;
    }

    public get EnvironmentUrlApiUrl(){
        return window.config.environmentUrl.API_URL;
    }

    public get EnvironmentLSA(){
        return window.config.environmentLSA.API_URL;
    }

    public get EnvironmentDocuSign(){
        return window.config.environmentDocuSign.API_URL;
    }

    public get SubjectivityToken(){
        return window.config.environmentUrl.API_KEY_SUBJECTIVITY;
    }

    public get LSAToken(){
        return window.config.environmentLSA.API_KEY_IMS_SERVICE;
    }

    public get DocuSignToken(){
        return window.config.environmentDocuSign.API_KEY_DOCUSIGN;
    }

    public get DocuSignDirect(){
        return window.config.environmentDocuSignDirect.API_URL;
    }
    public get returnURLDocusign(){
        return window.config.returnURLDocusign;
    }

}