<section>
  <div>
    <div *ngFor="let f of filesArray; let i = index; last as isLast">
      <div class="row h-50" *ngIf="!f.isWebForm">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-1 ic-condition">
              <fa-icon
                class="tcg is-condition icon-opacity"
                [icon]="faFileIcon"
              >
              </fa-icon>
            </div>
            <div class="col-md-11 c-condition">
              <h4 class="text-condition">{{ f.condition }}</h4>
              <label
                *ngIf="f.isBroker && !f.isInsured"
                class="tcgray label-condition"
                >To be Signed by licensed surplus line broker</label
              >
              <label
                *ngIf="f.isInsured && !f.isBroker && !f.done"
                class="tcgray label-condition"
              >
                <button
                  (click)="DowloadFunction(f.file, f.condition)"
                  type="button"
                  class="btn btn-link custom"
                >
                  Download
                </button>
                and upload signed document or send to insured via email for
                electronic signing.
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-4 tar">
          <!--Send Button-->
          <button
            *ngIf="
              f.isSignatureRequired &&
              !f.isUpload &&
              f.isInsured &&
              !f.isDone &&
              !f.isWebForm &&
              !f.isPending
            "
            class="btn btn-custom bcg tcw"
            (click)="onSend(f.file, f.condition, f.conditionID, f.templateId)"
            [disabled]="isInsuredClick"
          >
            Send Document
            <fa-icon
              class="tcw icon-fs-medium pl-icon"
              [icon]="faPaperPlaneIcon"
            ></fa-icon>
          </button>

          <!-- Resend  Insured -->
          <button
            *ngIf="
              f.isSignatureRequired &&
              !f.isUpload &&
              f.isPending &&
              !f.isWebForm &&
              !f.isDone &&
              f.isInsured
            "
            class="btn btn-custom bcg tcw"
            (click)="onReSend(f.file, f.condition, f.conditionID, f.templateId)"
            [disabled]="isInsuredClick"
          >
            Resend Document
            <fa-icon
              class="tcw icon-fs-medium pl-icon"
              [icon]="faPaperPlaneIcon"
            ></fa-icon>
          </button>

          <!-- Upload Document Insured -->
          <label
            class="label-or-document"
            *ngIf="
              f.isSignatureRequired &&
              !f.isUpload &&
              f.isInsured &&
              !f.isDone &&
              !f.isWebForm
            "
          >
            OR</label
          >
          <button
            *ngIf="
              f.isSignatureRequired &&
              !f.isUpload &&
              f.isInsured &&
              !f.isDone &&
              !f.isWebForm &&
              f.isAttached
            "
            (click)="openDialogUploadFile(f)"
            class="btn btn-upload-custom ngx-drop-custom tc tcgray"
            style="margin-bottom: 12px"
          >
            Attach file
            <fa-icon class="tcgray pl-icon" [icon]="faPaperclipIcon"></fa-icon>
          </button>

          <!--Upload Button-->
          <button
            *ngIf="
              f.isSignatureRequired &&
              !f.isUpload &&
              f.isInsured &&
              !f.isDone &&
              !f.isWebForm &&
              !f.isAttached
            "
            class="btn btn-upload-custom bcg tcw"
            (click)="
              uploadFile(
                f.file,
                i,
                f.fileName,
                f.conditionID,
                f.fileDescription,
                f.documentTypeGuid,
                f.folderId,
                f.folderName
              )
            "
          >
            Upload
            <fa-icon
              class="tcw icon-fs-medium pl-icon"
              [icon]="faUploadIcon"
            ></fa-icon>
          </button>

          <div
            (click)="removeFile(f.conditionID, i)"
            *ngIf="
              f.isSignatureRequired &&
              !f.isUpload &&
              f.isInsured &&
              !f.isDone &&
              !f.isWebForm &&
              !f.isAttached
            "
            class="rf-c tc"
          >
            <label class="rf-l">
              <fa-icon
                class="tcr icon-fs-medium pl-icon"
                [icon]="faTimesCircleIcon"
              ></fa-icon>
            </label>
          </div>
          <div
            *ngIf="
              f.isSignatureRequired &&
              !f.isUpload &&
              f.isInsured &&
              !f.isDone &&
              !f.isWebForm &&
              !f.isPending &&
              !f.isAttached
            "
          >
            &nbsp;
          </div>

          <!-- End Upload Document Insured -->

          <!--Sign Button-->
          <div
            class="sign-container"
            *ngIf="
              f.isSignatureRequired &&
              !f.isUpload &&
              !f.isDone &&
              !f.isWebForm &&
              f.isPending &&
              f.isBroker
            "
          >
            <button
              [disabled]="isBrokerClick"
              class="btn btn-custom bcg tcw"
              (click)="
                onSign(
                  f.file.fileContents,
                  f.condition,
                  '',
                  f.conditionID,
                  f.templateId,
                  f.conditionStatus
                )
              "
            >
              Sign
              <fa-icon
                class="tcw icon-fs-medium pl-icon"
                [icon]="faPenNibIcon"
              ></fa-icon>
            </button>
            <mat-progress-spinner
              diameter="30"
              mode="indeterminate"
              style="margin-left: 12px"
              *ngIf="showSignSpinner"
              color="accent"
            >
            </mat-progress-spinner>
            <span *ngIf="showSignSpinner"
              >DocuSign is processing your signature, <br />this process can
              take up to 60 seconds.</span
            >
          </div>

          <div
            class="sign-container"
            *ngIf="
              f.isSignatureRequired &&
              !f.isUpload &&
              !f.isDone &&
              f.isBroker &&
              !f.isWebForm &&
              !f.isPending
            "
          >
            <button
              [disabled]="isBrokerClick"
              class="btn btn-custom bcg tcw"
              (click)="
                onSign(
                  f.file.fileContents,
                  f.condition,
                  '',
                  f.conditionID,
                  f.templateId,
                  f.conditionStatus
                )
              "
            >
              Sign
              <fa-icon
                class="tcw icon-fs-medium pl-icon"
                [icon]="faPenNibIcon"
              ></fa-icon>
            </button>
            <mat-progress-spinner
              diameter="30"
              mode="indeterminate"
              style="margin-left: 12px"
              *ngIf="showSignSpinner"
              color="accent"
            >
            </mat-progress-spinner>
            <span *ngIf="showSignSpinner"
              >DocuSign is processing your signature, <br />this process can
              take up to 60 seconds.</span
            >
          </div>

          <!--Already Signed-->
          <label
            class="tco io fw-600 fs-16"
            *ngIf="
              f.isSignatureRequired &&
              !f.isUpload &&
              f.isDone &&
              !f.isWebForm &&
              !f.isPending
            "
            >Signed
            <fa-icon
              class="tco fs-18 pl-icon"
              [icon]="faCheckCircleIcon"
            ></fa-icon
          ></label>

          <!--Attach file-->
          <button
            *ngIf="f.isUpload && !f.isWebForm && !f.isDone && f.isAttached"
            (click)="openDialogUploadFile(f)"
            class="btn btn-upload-custom ngx-drop-custom tc tcgray"
          >
            Attach file
            <fa-icon class="tcgray pl-icon" [icon]="faPaperclipIcon"></fa-icon>
          </button>

          <!--Already Uploaded-->
          <label
            class="tco io fw-600 fs-16"
            *ngIf="f.isUpload && f.isDone && !f.isWebForm"
            >Uploaded
            <fa-icon class="tco fs-18" [icon]="faCheckCircleIcon"></fa-icon
          ></label>
        </div>
        <hr *ngIf="!isLast" class="hr-custom" />
      </div>
    </div>
  </div>
</section>
