<div mat-dialog-title class="title-section">
  Upload Document <mat-icon [mat-dialog-close]="">close</mat-icon>
</div>
<div mat-dialog-content>
  <div>
    <ngx-dropzone
      (change)="onSelect($event)"
      #drop
      class="drag-drop-container"
      accept="application/pdf,image/jpeg,.doc,.docx,.csv,.xlsx"
      [multiple]="false"
    >
      <ngx-dropzone-label>
        <div class="icons">
          <img
            src="/assets/images/upload-file-dialog/file-image.svg"
            alt="file-image"
          />
          <img
            class="center-icon"
            src="/assets/images/upload-file-dialog/file-pdf.svg"
            alt="file-pdf"
          />
          <img
            src="/assets/images/upload-file-dialog/file-excel.svg"
            alt="file-excel"
          />
        </div>
        <div class="label">
          <strong
            >Drag & Drop your files <br />
            or select
            <span class="green-color"> here </span>
            to browse.</strong
          >
        </div>
        <div class="ext-files">
          We support: .doc .docx, .xls, .xlsx, .pdf, .txt, .png, .jpg
        </div>
      </ngx-dropzone-label>
    </ngx-dropzone>
    <div class="maximum-size-for-file">Maximum size for file is 20 MB.</div>
    <ngx-dropzone-preview
      *ngFor="let f of files"
      [removable]="false"
      (removed)="onRemove(f)"
      class="preview-files"
    >
      <ngx-dropzone-label
        ><div>{{ f.name }}</div>
        <mat-icon (click)="onRemove(f)">close</mat-icon></ngx-dropzone-label
      >
    </ngx-dropzone-preview>
  </div>
</div>
<div mat-dialog-actions class="btn-actions-container">
  <button
    class="btn-browser"
    (click)="drop.showFileSelector()"
    [disabled]="uploading"
  >
    Browse Application
  </button>
  <button
    class="btn-submit"
    [disabled]="files?.length < 1 || !files || uploading"
    (click)="uploadApplication()"
  >
    Submit
  </button>
  <div *ngIf="uploading">
    <mat-progress-spinner
      diameter="30"
      mode="indeterminate"
      style="margin-left: 12px"
      color="accent"
    >
    </mat-progress-spinner>
  </div>
</div>
