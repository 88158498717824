import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ValidateTokenService {

  API_URL: any = environment.EnvironmentUrlApiUrl;
  TOKEN: any = environment.SubjectivityToken;

  constructor(private httpClient: HttpClient) { }

  validateToken(formData: any) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.TOKEN
    });

    return this.httpClient
      .post(
        this.API_URL + 'ValidateToken',
        formData,
        {
          headers,
          reportProgress: false,
          observe: 'response',
          responseType: 'text'
        }
      )
  }
}
