<div>
    <section class="background">
        <div class="container">
            <div class="row">
                <div class="col-md-12 mt-22">
                    <h2 class="tcw text-center">Browser is not supported.</h2>
                    <h2 class="tcw text-center">To improve your experience we recommend to download the following options.</h2>
                </div>
            </div>
            <br><br><br>
            <div class="row" style="text-align:center">
                <div class="col-md-4 d-flex flex-column justify-content-center align-items-center">
                    <img src="../../../assets/images/browsers/chrome_icon.svg" alt="chrome" height="96">
                    <br>
                    <a class="label_browser" href="https://www.google.com/intl/en/chrome/">Chrome</a>
                </div>
                <div class="col-md-4 d-flex flex-column justify-content-center align-items-center">
                    <img src="../../../assets/images/browsers/edge_icon.svg" alt="edge" height="96">
                    <br>
                    <a class="label_browser" href="https://www.microsoft.com/en-us/edge">Edge</a>
                </div>
                <div class="col-md-4 d-flex flex-column justify-content-center align-items-center">
                    <img src="../../../assets/images/browsers/firefox_icon.svg" alt="firefox" height="96">
                    <br>
                    <a class="label_browser" href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>
                </div>
            </div>
        </div>
    </section>    
</div>
