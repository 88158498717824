import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import {
  faFileWord, faFilePdf, faFileImage,
  faFileCsv, faFileDownload, faPen, faInfoCircle, faFile, faUser, faEdit, faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { IpService } from 'src/app/Services/ip-service';
import { ListDocumentsEmitterService } from 'src/app/Services/list-documents-emitter.service';
import Swal from 'sweetalert2';
import { BinderPolicyService } from 'src/app/Services/binder-policy.service';
import { environment } from 'src/environments/environment';
import { InsuredDataService } from 'src/app/Services/insured-data.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, Validators, FormGroupDirective, NgForm, UntypedFormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import * as signalR from '@microsoft/signalr';
import { DialogDwFileComponent } from 'src/app/shared/dialog-dw-file/dialog-dw-file.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LogService, Level } from './../../Services/log.service';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-insured',
  templateUrl: './insured.component.html',
  styleUrls: ['./insured.component.scss']
})
export class InsuredComponent implements OnInit, OnDestroy {

  APIURLDocusign: any = environment.DocuSignDirect;
  TOKEN: any = environment.DocuSignToken;
  isSupported = false;
  isIE = false;
  inspectionContacLocation: string;
  private loopSuscriptorStatus: Subscription;

  // ** Sockets implementation */
  options: signalR.IHttpConnectionOptions = {
    accessTokenFactory: () => this.TOKEN
  };
  connectedSocket = false;

  connection = new signalR.HubConnectionBuilder()
    .withUrl(this.APIURLDocusign.slice(0, -4) + 'Emmiter')
    .build();

  // Arrays
  files: any[] = [];
  filesRequired: any = [];
  filesPreBindAttach: any[] = [];
  filesPreBindSignature: any[] = [];
  filesPreIssuanceAttach: any[] = [];
  filesPreIssuanceSignature: any[] = [];
  filesPolicy: any[] = [];

  // Insured Data
  magicLinkId = '';
  insuredData: any;
  insuredName: string;
  insuredEmail: any = '';
  effectiveDate: string;
  controlNumber: any = '';
  policyNumber: any = '';
  isWebForm: any = false;
  isWebFormDone: any = '';
  conditionIdInspectionContact: any = '';

  // Insured Data Emmited
  @Output() insuredDataEmmited: EventEmitter<any>;

  // Other Variables
  urlLogo: any = '';
  ip: any;
  fileIconArray: any = [];
  isCatalytic = false;
  isEmptyAccount = false;
  quoteGuid = '';

  // Policy and Binder
  policyDocuments: any[] = [];
  IsBinderReady: boolean;
  IsPolicyReady: boolean;
  isBinderReadyDisabled = true;
  isPolicyReadyDisabled = true;

  // Icons
  faFileDownloadIcon: any = faFileDownload;
  faPenIcon: any = faPen;
  faInfoCircleIcon: any = faInfoCircle;
  faFileIcon: any = faFile;
  faUserIcon: any = faUser;
  faEditIcon: any = faEdit;
  faCheckCircleIcon: any = faCheckCircle;


  // Files Data Section
  listDocumentsArray: any[] = [];

  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
  ]);

  matcher = new MyErrorStateMatcher();

  constructor(
    private ipService: IpService, private eventEmttrSrvc: ListDocumentsEmitterService,
    private binderPolicySrvc: BinderPolicyService, private insuredSrvc: InsuredDataService,
    private activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, public dialog: MatDialog,
    private deviceService: DeviceDetectorService, private logService: LogService
  ) { }

  ngOnInit() {

    this.validateBrowser();
    // Get Full Data
    this.getInsuredFullData();

    // Load Footer Icons
    this.loadListIcons();

    // Get Policy Documents
    this.getPolicyFiles();

    // Get Ip
    this.getIP();

  }

  getIP() {
    this.ipService.getIpAddress()
      .subscribe((res: any) => {
        this.ip = res.ip;
      });
  }

  refreshData(conditionStatus: string) {
    if (this.loopSuscriptorStatus) {
      this.loopSuscriptorStatus.unsubscribe();
    }

    const inter = interval(5000);
    const takeFourInter = inter.pipe(take(12));
    this.loopSuscriptorStatus = takeFourInter.subscribe((x) => {
      this.getInsuredFullData(true, conditionStatus);
    });

  }

  ngOnDestroy(): void {
    if (this.loopSuscriptorStatus) {
      this.loopSuscriptorStatus.unsubscribe();
    }
  }

  loadListIcons() {
    this.fileIconArray = [
      {
        fileTitle: 'Word',
        fileIcon: faFileWord
      },
      {
        fileTitle: 'PDf',
        fileIcon: faFilePdf
      },
      {
        fileTitle: 'JPG',
        fileIcon: faFileImage
      },
      {
        fileTitle: 'CSV',
        fileIcon: faFileCsv
      },
      {
        fileTitle: 'Excel',
        fileIcon: faFileImage
      }
    ];
  }

  updateInsuredEmail() {
    this.insuredEmail = this.emailFormControl.value;

    const formData = new FormData();
    formData.append('magicLinkId', this.magicLinkId);
    formData.append('insuredEmail', this.insuredEmail);

    this.insuredSrvc.updateInsuredEmail(formData)
      .subscribe(response => {
        this.sweetAlert('Email Updated', 'success');

        setTimeout(() => {
          location.reload();
        }, 1000);
      },
        err => {
          this.logService.Log(err, Level.ERROR);
        });
  }

  getInsuredFullData(isFromEmit = false, conditionStatus: string = '') {

    this.magicLinkId = this.activatedRoute.snapshot.params.magicLinkId;
    const formData = new FormData();
    formData.append('magicLinkId', this.magicLinkId);

    this.insuredSrvc.getInsuredFullData(formData)
      .subscribe((insuredData: any) => {
        if (insuredData !== undefined) {

          if(this.loopSuscriptorStatus && isFromEmit) {
            const filesTemp = insuredData.body.subjectivityFiles;
            const docs = filesTemp.filter(x => x.conditionStatus == conditionStatus && x.isDone && x.isUpload && !x.isWebForm);
            if(docs && docs.length > 0 ) {
              this.loopSuscriptorStatus.unsubscribe();
            }else {
              return;
            }
          }

          this.insuredData = insuredData.body.insuredData;
          this.files = insuredData.body.subjectivityFiles;
          this.insuredName = this.insuredData.insuredName;
          this.effectiveDate = this.insuredData.effectiveDate;
          this.controlNumber = this.insuredData.controlNumber;
          this.policyNumber = this.insuredData.policyNumber;
          this.emailFormControl.setValue(this.insuredData.insuredEmail);
          this.insuredEmail = this.emailFormControl.value;
          this.quoteGuid = this.insuredData.quoteGuid;

          if (!this.connectedSocket) {
            // this.startConnection();
          }

          // Separate Policy Number Company Type
          this.isCatalytic = (this.insuredData.company === 'catalytic' || this.insuredData.company === 'dual') ? true : false;

          // Emmit Data To Documents Component
          this.eventEmttrSrvc.data = this.insuredData;

          // Logo
          this.urlLogo = this.getUrlLogo(this.insuredData.company);

          // Fill Policy Documents
          this.fillPolicyDocumentsData();

          // Fill Files Array
          this.assignFiles(this.files);

          // Filter isWebForm Value
          const file = this.files.find(file => file.isWebForm);

          if (file) {
            this.isWebForm = true;
            this.conditionIdInspectionContact = file.conditionID;
            this.isWebFormDone = file.isDone;
            this.inspectionContacLocation = file.conditionStatus;
          }
        }
      });
  }

  fillPolicyDocumentsData() {

    const data = this.insuredData;
    // Policy Documents Data
    this.filesPolicy = [
      {
        title: 'Policy Numbers and Invoice',
        downloadText: 'Download Policy Numbers and Invoice',
        isDocumentGenerated: data.isPolicyNumberGenerated,
        isDocumentReady: data.isPolicyNumberReady,
        type: 'pni'
      },
      {
        title: 'Binder',
        downloadText: 'Download Binder',
        isDocumentGenerated: data.isBinderGenerated,
        isDocumentReady: data.isBinderReady,
        type: 'binder'
      },
      {
        title: 'Policy',
        downloadText: 'Download Policy',
        isDocumentGenerated: data.isPolicyGenerated,
        isDocumentReady: data.isPolicyReady,
        type: 'policy'
      }
    ];
  }

  assignFiles(files: any) {

    let counter = 0;
    this.addNewAttributeTofilesArray(files);
    this.filesPreBindSignature = [];
    this.filesPreBindAttach = [];
    this.filesPreIssuanceSignature = [];
    this.filesPreIssuanceAttach = [];
    this.filesRequired = [];

    files.filter((element: any) => {

      if (element.conditionStatus === 'Pre-Bind' && element.isSignatureRequired) {
        this.filesPreBindSignature.push(element);
        if (this.filesPreBindSignature.length === 0) { counter++; }
      }

      if (element.conditionStatus === 'Pre-Bind' && element.isUpload) {
        this.filesPreBindAttach.push(element);
        if (this.filesPreBindAttach.length === 0) { counter++; }
      }

      if (element.conditionStatus === 'Pre-Issuance' && element.isSignatureRequired) {
        this.filesPreIssuanceSignature.push(element);
        if (this.filesPreIssuanceSignature.length === 0) { counter++; }
      }

      if (element.conditionStatus === 'Pre-Issuance' && element.isUpload) {
        this.filesPreIssuanceAttach.push(element);
        if (this.filesPreIssuanceAttach.length === 0) { counter++; }
      }

      if (element.conditionStatus === 'Required') {
        this.filesRequired.push(element);
        if (this.filesRequired.length === 0) { counter++; }
      }

      if (counter === 5) { this.isEmptyAccount = true; }

    });

  }

  addNewAttributeTofilesArray(files: any) {
    files.forEach(element => {
      element.isAttached = true;
    });
  }


  getPolicyFiles() {
    const formData = new FormData();
    formData.append('magicLinkId', this.magicLinkId);
    this.binderPolicySrvc.postReturnPolicyDocuments(formData)
      .subscribe((response: any) => {
        this.policyDocuments.push(response.body);
      });
  }

  downloadPolicies(type: any) {
    const dialogRef = this.dialog.open(DialogDwFileComponent, {
      width: '400px',
      data: { quoteGuid: this.quoteGuid }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.policyDocuments[0].forEach(element => {
          if (type === 'binder' && element.documentDescription === 'Binder') {
            const nameFileDowload = 'Binder ' + this.insuredName + '.pdf';
            this.DowloadFunction(element, result, nameFileDowload);
          } else if (type === 'policy' && element.documentDescription === 'Policy') {
            const nameFileDowload = 'Policy ' + this.insuredName + '.pdf';
            this.DowloadFunction(element, result, nameFileDowload);
          } else if (type === 'pni' && element.documentDescription === 'Policy Numbers and Invoice') {
            const nameFileDowload = 'Policy Numbers and Invoice ' + this.insuredName + '.pdf';
            this.DowloadFunction(element, result, nameFileDowload);
          }
        });
      }
    });
  }

  DowloadFunction(element, result, nameFileDowload) {
    if (this.isSupported) {
      const link = document.createElement('a');
      const fileBlob = this.converBase64toBlob(element.file.fileContents, 'application/pdf');
      link.download = nameFileDowload;
      const url = window.URL.createObjectURL(fileBlob);
      link.href = url;
      link.click();
      this.SaveLogDocumentDownloaded(element.documentStoreGUID, result.name, result.email);
    } else {
      const fileBlob = this.converBase64toBlob(element.file.fileContents, 'application/pdf');
      const url = window.URL.createObjectURL(fileBlob);
      try {
        window.open(url);
      } catch (err) {
        this.logService.Log('Subjectivity App: Error File Download', Level.ERROR);
      }

      this.SaveLogDocumentDownloaded(element.documentStoreGUID, result.name, result.email);
    }
  }

  SaveLogDocumentDownloaded(documentStoreGUID: string, name: string, email: string) {
    const formData = new FormData();
    formData.append('documentStoreGUID', documentStoreGUID);
    formData.append('ip', this.ip);
    formData.append('quoteGUID', this.quoteGuid);
    formData.append('name', name);
    formData.append('emailAddress', email);
    this.insuredSrvc.postSaveLogDocumentDownloaded(formData).subscribe(res => {
      this.logService.Log('Subjectivity App: Save Log Success', Level.INFO);
    });
  }

  checkSessionTime(): boolean {
    const SESSION_DURATION = environment.SessionDuration;
    const sessionDate = sessionStorage.getItem('date');
    const dateDate = new Date(sessionDate).getTime();
    const toDay = Date.now();

    const msec = (toDay - dateDate);
    const mins = Math.floor(msec / 60000);

    if (mins > Number(SESSION_DURATION)) {
      // this.showMesage(this.modal);
      return false;
    } else {
      // dateTiem update by activity
      sessionStorage.setItem('date', Date());
    }
    return true;
  }

  getUrlLogo(Company: string): string {
    if (Company === 'align') {
      return './assets/images/alignfinancialLogo.svg';
    } else if (Company === 'catalytic') {
      return './assets/images/catalytic-logo.png';
    } else if (Company === 'dual') {
      return './assets/images/dualNa-logo.png'
    } else {
      return './assets/images/aling_catalytic1.png';
    }
  }

  async sweetModalEmail(title: any, icon: any) {
    const { value: email } = await Swal.fire({
      title,
      input: 'email',
      inputPlaceholder: 'insured@email.com',
      icon
    });
    if (email) {
      this.insuredEmail = email;
      this.emailFormControl.setValue(this.insuredEmail);
      this.updateInsuredEmail();
    }
  }

  sweetAlert(message: any, icon: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
    });

    Toast.fire({
      icon,
      title: message,
      customClass: {
        container: 'toast-custom',
        icon: 'toast-icon',
        title: 'toast-title',
      },
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: { insuredData: this.insuredData, conditionId: this.conditionIdInspectionContact, ip: this.ip }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.logService.Log('Subjectivity App: The dialog was closed, result: ' + result, Level.LOG);
    });
  }

  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    const sliceSize = 512;
    const byteCharacters = window.atob(content); // method which converts base64 to binary
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: contentType
    }); // statement which creates the blob
    return blob;
  }

  // ** Sockets implementation */
  startConnection() {
    this.connection.start().then(() => {
      this.connectedSocket = true;
      this.joinGroup(this.quoteGuid);
      // this.receiveValues();
    }).catch(err => {
      this.logService.Log('Subjectivity App: Conection socket Not Found', Level.ERROR);
    });
  }

  receiveValues() {
    this.connection.on('emitValue', (res: any, res2: any) => {

      if (res === 'completed') {
        this.logService.Log('Update Values', Level.LOG);
        this.getInsuredFullData();
      } else if (res === 'errorConnect') {
        this.logService.Log('Subjectivity App: Connect Error ' + res2, Level.ERROR);
      } else {
        this.logService.Log('Subjectivity App: Message not found', Level.INFO);

      }
    });
  }

  joinGroup(group: string): void {
    if (this.connection) {
      this.connection.invoke('AddGroup', group).then(() => {
        this.logService.Log('Add Group', Level.LOG);
        this.receiveValues();

      }).catch((error) => {
        this.logService.Log(error, Level.ERROR);
      });
    }
  }
  /** END SOCKETS */

  /** Sopporting Browser */
  validateBrowser() {
    const browser = this.deviceService.browser;
    const version = parseFloat(this.deviceService.browser_version);

    switch (browser) {
      case 'Chrome':
        if (version >= 81) {
          this.isSupported = true;
        }
        break;
      case 'IE':
        if (version >= 10) {
          this.isSupported = false;
        }
        this.isIE = true;
        break;
      case 'MS-Edge-Chromium':
        if (version >= 83) {
          this.isSupported = true;
        }
        break;
      case 'Opera':
        if (version >= 67) {
          this.isSupported = true;
        }
        break;
      case 'Safari':
      case 'MS-Edge':
        if (version >= 13) {
          this.isSupported = true;
        }
        break;
      default:
        this.isSupported = true;
    }
  }
  /** End Sopporting Browser */


}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
