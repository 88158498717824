import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LoginService } from '../Services/login.service';
import { ValidateTokenService } from '../Services/validate-token.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckAccessGuard implements CanActivate {
  magicLinkId: string = "";
  constructor(private router: Router, private loginSrvc: LoginService, private validateTokenSrvc: ValidateTokenService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree | any> | Promise<boolean | UrlTree> | boolean | UrlTree {


    this.magicLinkId = next.params.magicLinkId;
    let token = sessionStorage.getItem("token");

    let formData = new FormData();
    formData.append('token', token);
    formData.append('magicLinkId', this.magicLinkId);

    return this.validateTokenSrvc.validateToken(formData)
      .pipe(
        map((response: any) => {

          if (response.body === "SessionCorrect")
            return true;
          else if (response.body === "MagicLinkIdDoesNotMatch") {
            this.router.navigateByUrl('Login/' + this.magicLinkId);
            return false;
          } else if (response.body === 'NotFound') {
            this.router.navigateByUrl('NotFound');
            return false;
          } else if (response.body === 'SessionClose') {
            this.router.navigateByUrl('Login/' + this.magicLinkId);
            return false;
          }
        }),
        catchError((err: any) => {
          this.router.navigateByUrl('Login/' + this.magicLinkId);
          return of(false);
        })
      );
  }

}
