import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { UploadFileService } from "src/app/Services/upload-file.service";
import { ValidateTokenService } from "src/app/Services/validate-token.service";
import Swal from "sweetalert2";
import { DataUploadFile } from "src/app/shared/interfaces/dataUploadFile";

@Component({
  selector: "app-dialog-upload-file",
  templateUrl: "./dialog-upload-file.component.html",
  styleUrls: ["./dialog-upload-file.component.scss"],
})
export class DialogUploadFileComponent {
  maxFileSize = 20480; // in Kilobyte (KB)
  files: File[] = [];
  uploading = false;

  constructor(
    private uplodafileSrvc: UploadFileService,
    private tkService: ValidateTokenService,
    public dialogRef: MatDialogRef<DialogUploadFileComponent>,
    @Inject(MAT_DIALOG_DATA) public dataLoad: DataUploadFile
  ) {}

  onSelect(event) {
    if (event.addedFiles.length > 0) {
      // it is validated that it does not exceed
      if (event.addedFiles[0].size / 1024 <= this.maxFileSize) {
        this.files[0] = event.addedFiles[0];
      } else {
        this.sweetAlert(
          "File exceeds max size. The size of the file to upload is: " +
            this.handleFileSelected(event.addedFiles[0].size),
          "error"
        );
      }
    } else {
      this.sweetAlert("Not file was added.", "error");
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  uploadApplication() {
    for (let file of this.files) {
      this.uploading = true;
      this.generateFormData(file);
    }
  }

  generateFormData(file: any) {
    if (this.checkSessionTime()) {
      const formData = new FormData();
      formData.append("QuoteGuid", this.dataLoad.quoteGuid);
      formData.append("controlNo", this.dataLoad.controlNumber);
      formData.append("fileName", this.dataLoad.fileName);
      formData.append("fileData", file);
      formData.append("magicLinkId", this.dataLoad.magicLinkId);
      formData.append("fileConditionId", this.dataLoad.conditionId);
      formData.append("ip", this.dataLoad.ip);
      formData.append("fileDescription", this.dataLoad.fileDescription);
      if (this.dataLoad.documentTypeGuid) {
        formData.append("documentTypeGuid", this.dataLoad.documentTypeGuid);
      }
      formData.append("folderId", this.dataLoad.folderId);
      formData.append("folderName", this.dataLoad.folderName);
      this.uploadFileCall(formData);
    }
  }

  uploadFileCall(formData: FormData) {
    this.uplodafileSrvc
      .sendFileToLSAAPI(formData)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return of("Upload Failed" + error);
        })
      )
      .subscribe(
        (response: any) => {
          if (response !== undefined) {
            // Needs to change the value when file isDone
            if (response.body !== undefined && response.body !== null) {
              this.sweetAlert("File Uploaded Succesfully", "success");
              this.dialogRef.close(true);
            } else {
              this.sweetAlert("File Uploaded Error", "error");
              this.uploading = false;
            }
          }
        },
        (error) => {
          this.sweetAlert("File Uploaded Error", "error");
          this.uploading = false;
        }
      );
  }

  checkSessionTime(): any {
    const token = sessionStorage.getItem("token");
    const formData = new FormData();
    formData.append("token", token);
    formData.append("magicLinkId", this.dataLoad.magicLinkId);

    return this.tkService.validateToken(formData).subscribe((response) => {
      if (response.body === "SessionCorrect") {
        return true;
      }
      return false;
    });
  }

  sweetAlert(message: any, icon: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
    });

    Toast.fire({
      icon,
      title: message,
      customClass: {
        container: "toast-custom",
        icon: "toast-icon",
        title: "toast-title",
      },
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  }

  handleFileSelected(size: number) {
    // size in bytes
    if (size < 1024) {
      return size.toFixed(2) + " bytes";
    } else if (size < 1024 * 1024) {
      return (size / 1024).toFixed(2) + " kb";
    } else if (size < 1024 * 1024 * 1024) {
      return (size / 1024 / 1024).toFixed(2) + " mb";
    } else {
      return (size / 1024 / 1024 / 1024).toFixed(2) + " gb";
    }
  }
}
