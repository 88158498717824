import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListDocumentsEmitterService {

  data: any;
  insuredData = new EventEmitter<any>();
  insuredName = new EventEmitter<String>();
  effectiveDate = new EventEmitter<String>();
  isBinderReady = new EventEmitter<String>();
  isPolicyReady = new EventEmitter<String>();
  magicLinkId = new EventEmitter<any>();
  completedSignData: any = {};
  company: any = new EventEmitter<any>();

  constructor() { }
}
