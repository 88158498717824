import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { loginData } from '../shared/loginInterface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  //Call InsuredData Controller that will return the data used in the LoginPage
  API_URL: any = environment.EnvironmentUrlApiUrl;
  TOKEN: any = environment.SubjectivityToken;

  // API_URL: any = 'http://localhost:52257/api/';

  constructor(private httpClient: HttpClient) { }

  getLoginData(formData: any): Observable<loginData> {

    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.TOKEN
    });

    return this.httpClient
      .post<loginData>(
        this.API_URL + 'Login',
        formData,
        {
          headers
        }
      )
  }

  getLoginAccess(formData: any) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.TOKEN
    });

    return this.httpClient
      .post(
        this.API_URL + 'Login',
        formData,
        {
          headers,
          reportProgress: false,
          observe: 'response'
        }
      )
  }
}
