import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})

export class LogService {
  API_URL_SUBJECTIVITY: any = environment.EnvironmentUrlApiUrl;
  TOKEN_SUBJECTIVITY: any = environment.SubjectivityToken;
  headersSubjectivity = new HttpHeaders({
    'Authorization': 'Bearer ' + this.TOKEN_SUBJECTIVITY
  });


  constructor(private logger: NGXLogger) {
    const configSnapshot = this.logger.getConfigSnapshot();
    configSnapshot.serverLoggingUrl = this.API_URL_SUBJECTIVITY + 'Log';
    configSnapshot.customHttpHeaders= this.headersSubjectivity;
    this.logger.updateConfig(configSnapshot);
   }

  Log(message:any, level : Level) {
    switch(level){
      case Level.DEBUG:
        this.logger.debug(message);
        break;
      case Level.INFO:
        this.logger.info(message);
        break
      case Level.LOG:
        this.logger.log(message);
        break;
      case Level.WARN:
        this.logger.warn(message);
        break;
      case Level.ERROR:
        this.logger.error(message);
        break;
      case Level.FATAL:
        this.logger.fatal(message);
        break;
    }
  }
}


export enum Level  {
  DEBUG = 1,
  INFO = 2,
  LOG = 3,
  WARN = 4,
  ERROR = 5,
  FATAL = 6
}
