import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocuSignService } from 'src/app/Services/docu-sign.service';
import { map, concatMap } from 'rxjs/operators';
import { UploadFileService } from 'src/app/Services/upload-file.service';
import { HttpEventType } from '@angular/common/http';
import { ListDocumentsEmitterService } from 'src/app/Services/list-documents-emitter.service';
import { pipe } from 'rxjs';
import { InsuredDataService } from 'src/app/Services/insured-data.service';

@Component({
  selector: 'app-completed-sign',
  templateUrl: './completed-sign.component.html',
  styleUrls: ['./completed-sign.component.scss']
})
export class CompletedSignComponent implements OnInit, OnDestroy {

  guid: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private docuSignSrvc: DocuSignService, private uplodafileSrvc: UploadFileService, private eventEmitterSrvc: ListDocumentsEmitterService, private insuredDataService: InsuredDataService) {
    this.guid = this.activatedRoute.snapshot.params.guid;
  }

  ngOnInit(): void {
    // this.callDocuSignApi();
    //Send Message Parent Iframe
    window.parent.postMessage("CloseModal", "*");
  }

  ngOnDestroy() {
    localStorage.removeItem('completedSignData');
  }

  callDocuSignApi() {
    let fileBlob: any = '';
    let formData = new FormData();

    this.docuSignSrvc.postGuidDocument(this.guid)
      .pipe(
        concatMap<any, any>((fileResponse: any) => {

          fileBlob = this.converBase64toBlob(fileResponse.body, 'application/pdf');

          let result2 = JSON.parse(localStorage.getItem('completedSignData'));

          formData.append('QuoteGuid', result2.insuredData.quoteGuid);
          formData.append('controlNo', result2.insuredData.controlNumber);
          formData.append('fileName', result2.fileName);
          formData.append('fileData', fileBlob);
          formData.append('magicLinkId', result2.magicLinkId);
          formData.append('fileConditionId', result2.conditionId);
          formData.append('ip', result2.ip);

          return this.uplodafileSrvc.sendFileToLSAAPI(formData);
        })
      )
      .subscribe((responseFinal: any) => {
      })
  }

  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }

}
