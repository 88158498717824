import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { environmentUrl } from 'src/environments/environment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BinderPolicyService {

  API_URL: any = environment.EnvironmentLSA;
  TOKEN: any = environment.LSAToken;
  headersLSA = new HttpHeaders({
    'Authorization': 'Bearer ' + this.TOKEN
  });

  constructor(private httpClient: HttpClient) { }

  postReturnPolicyDocuments(formData: any){
    return this.httpClient
      .post(
        this.API_URL + 'BinderPolicy',
        formData,
        {
          headers: this.headersLSA,
          reportProgress: false,
          observe: 'response'
        }
      )
  }
}
