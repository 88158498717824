<div class="background">
    <section class="container container-custom">
        <div class="row vh-100 d-flex align-items-center">
            <div class="col-md-12 contentRow">
                <div class="mb-5 text-center">
                    <img class="img-custom" *ngIf="logoExists" [src]="logoExists" alt="logo">
                </div>
                <div class="d-flex justify-content-center">
                    <div class="box-fil col-md-5" *ngIf="logoExists">
                        <div class="m-4">
                            <label class="insured">{{insuredName}}</label>
                            <!-- <input type="text" class="form-control text-center" autocomplete="false"
                                placeholder="Insured Name" [(ngModel)]="insuredName" /> -->
                        </div>

                        <div class="m-4">
                            <div class="input-group">
                                <input class="form-control py-2 border-right-0 border" type="password"
                                    placeholder="Password" [(ngModel)]="password" autofocus>
                                <span class="input-group-append">
                                    <button class="border-left-0 border" type="button" (click)="login()">
                                        <fa-icon class="colorIcon" [icon]="iconArrow" size="2x"></fa-icon>
                                    </button>
                                </span>
                            </div>
                            <!-- <input type="password" class="form-control text-center" autocomplete="false"
                                placeholder="Password" [(ngModel)]="password" /> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<div class="div-notSopporting" *ngIf="!this.isSupported && !this.isIE">
    <div class="alert alert-warning m-auto" role="alert">
        This web site is best viewed with these compatible browsers <a href="https://www.google.com/chrome/">Download
            browser</a>
    </div>
</div>