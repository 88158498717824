import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InsuredDataService {

  // Call Login Controller that will return most of the data used in the InsuredPage
  API_URL: any = environment.EnvironmentLSA;
  TOKEN: any = environment.LSAToken;
  headersLSA = new HttpHeaders({
    Authorization: 'Bearer ' + this.TOKEN
  });

  API_URL_SUBJECTIVITY: any = environment.EnvironmentUrlApiUrl;
  TOKEN_SUBJECTIVITY: any = environment.SubjectivityToken;
  headersSubjectivity = new HttpHeaders({
    Authorization: 'Bearer ' + this.TOKEN_SUBJECTIVITY
  });

  constructor(private httpClient: HttpClient) { }

  getInsuredFullData(formData: any) {

    return this.httpClient
      .post(
        this.API_URL + 'InsuredData',
        formData,
        {
          headers: this.headersLSA,
          reportProgress: false,
          observe: 'response'
        }
      );
  }

  updateInsuredEmail(formData: any) {

    return this.httpClient
      .post(
        this.API_URL + 'InsuredUpdate',
        formData,
        {
          headers: this.headersLSA,
          reportProgress: false,
          observe: 'response',
          responseType: 'text'
        }
      );
  }

  postDocuSignData(formData: any) {
    return this.httpClient
      .post(
        this.API_URL_SUBJECTIVITY + 'DocuSign',
        formData,
        {
          headers: this.headersSubjectivity,
          reportProgress: false,
          observe: 'response'
        }
      );
  }

  postInspectionContactData(formData: any) {

    return this.httpClient
      .post(
        this.API_URL + 'InspectionContact',
        formData,
        {
          headers: this.headersLSA,
          reportProgress: false,
          observe: 'response',
          responseType: 'text'
        }
      );
  }

  postSaveLogDocumentDownloaded(formData: any) {
    return this.httpClient.post(this.API_URL_SUBJECTIVITY + 'SaveLog', formData,
      {
        headers: this.headersSubjectivity,
        reportProgress: false,
        observe: 'response',
      });
  }

  GetLogDocumentDownloaded(quoteGuid: string) {
    return this.httpClient.get(this.API_URL_SUBJECTIVITY + 'GetSubjPortal?quoteGUID=' + quoteGuid,
      {
        headers: this.headersSubjectivity,
        reportProgress: false,
        observe: 'response',
      });
  }
}
