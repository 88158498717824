import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "src/app/Services/login.service";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { DeviceDetectorService } from "ngx-device-detector";
import { LogService, Level } from "./../../Services/log.service";

//
import Swal from "sweetalert2";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  iconArrow = faArrowAltCircleRight;
  routeId: any;
  password: string = "";
  magicLinkId: string = "";
  insuredName: string = "";
  logoExists: string = "";
  flagInit: boolean = false;
  deviceInfo = null;
  time: any = null;
  isSupported: boolean = false;
  isIE: boolean = false;

  constructor(
    private loginSrvc: LoginService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private logService: LogService
  ) { }

  ngOnInit(): void {
    try {
      this.validateBrowser();
      this.routeId = this.activatedRoute.snapshot.params["magicLinkId"];

      if (this.routeId === undefined) this.router.navigateByUrl("NotFound");
      this.getLoginData();
    } catch (err) {
      this.logService.Log(err, Level.ERROR);
    }
  }

  validateBrowser() {
    const browser = this.deviceService.browser;
    const version = parseFloat(this.deviceService.browser_version);
    switch (browser) {
      case "Chrome":
        if (version >= 81) this.isSupported = true;
        break;
      case "IE":
        if (version >= 10) this.isSupported = false;
        this.isIE = true;
        break;
      case "MS-Edge-Chromium":
        if (version >= 83) this.isSupported = true;
        break;
      case "Opera":
        if (version >= 67) this.isSupported = true;
        break;
      case "Safari":
        if (version >= 13) this.isSupported = true;
        break;
      case "MS-Edge":
        if (version >= 13) this.isSupported = true;
        break;
      default:
        this.isSupported = true;
    }
  }

  //Get de data Insured
  getLoginData() {
    this.magicLinkId = this.activatedRoute.snapshot.params.magicLinkId;
    let formData = new FormData();
    formData.append("magicLinkId", this.magicLinkId);

    this.loginSrvc.getLoginData(formData).subscribe(
      (data) => {
        if (data) {
          this.insuredName = data.insuredName;
          this.logoExists = this.assignUrlLogo(data.company);
        } else {
          this.router.navigateByUrl("/NotFound");
        }
      },
      (err) => {
        this.logService.Log(err, Level.ERROR);
        this.router.navigateByUrl("/NotFound");
      }
    );
  }

  login() {
    this.magicLinkId = this.activatedRoute.snapshot.params.magicLinkId;
    let formData = new FormData();
    formData.append("magicLinkId", this.magicLinkId);
    formData.append("password", this.password);

    this.loginSrvc.getLoginAccess(formData).subscribe(
      (response) => {
        if (response.body["error"])
          this.sweetAlert("Incorrect password", "error");

        if (response.body["error"] === null) {
          sessionStorage.setItem("token", response.body["token"]);
          this.router.navigateByUrl("/" + this.magicLinkId);
        }
      },
      (err) => {
        this.logService.Log(err, Level.ERROR);
      }
    );
  }

  assignUrlLogo(Company: string): string {
    if (Company == "align") {
      return "./assets/images/alignfinancialLogo.svg";
    } else if (Company == "catalytic") {
      return "./assets/images/catalytic-logo.png";
    } else if (Company === 'dual') {
      return "./assets/images/dualNa-logo.png";
    } else {
      return "./assets/images/aling_catalytic1.png";
    }
  }

  sweetAlert(message: any, icon: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: icon,
      title: message,
      customClass: {
        container: "toast-custom",
        icon: "toast-icon",
        title: "toast-title",
      },
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  }
}
