<section class="container">
    <div class="row">
        <div class="col-md-12">

            <!--Logo-->
            <div>
                <img class="logo" [src]="urlLogo" alt="logo">
            </div>
            <!--End Logo-->

            <!--Title-->
            <div>
                <div>
                    <h3 class="tcg tc fw-500 ps-title">Policy Subjectivities</h3>
                </div>
                <hr class="hr-title">
            </div>
            <!--End Title-->

            <!--Insured Data-->
            <div class="row mt-2">
                <!--Insured Name, Effective Date and Insured Email-->
                <div class="col-md-9">

                    <div class="row">
                        <div class="col-md-12">
                            <label class="tcg fw-600 id-label">Insured Name: <label
                                    class="tcgray fw-500 id-label">{{insuredName}}</label></label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <label class="tcg fw-600 id-label">Effective Date: <label
                                    class="tcgray fw-500 id-label">{{effectiveDate | date: "MM/dd/yyyy"}}</label></label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="tcg fw-600 id-label e-label w100">
                                Insured Mail:
                                <form class="form-email w80">
                                    <mat-form-field class="tcgray font-style-custom w50 custom-form-field">
                                        <mat-icon class="email-icon" matSuffix>email</mat-icon>
                                        <input class="input-email" [value]="insuredEmail" type="email"
                                            name="insuredEmail" matInput [formControl]="emailFormControl"
                                            [errorStateMatcher]="matcher" (change)="updateInsuredEmail()">
                                        <mat-error
                                            *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                            Please enter a valid email address
                                        </mat-error>
                                        <mat-error *ngIf="emailFormControl.hasError('required')">
                                            Email is <strong>required</strong>
                                        </mat-error>
                                        <mat-icon class="email-edit-icon" matSuffix>mode_edit</mat-icon>
                                    </mat-form-field>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Control and Policy Number-->
                <div class="col-md-3 border-left">

                    <div class="row">

                        <div class="col-md-12">
                            <div *ngIf="controlNumber">
                                <label class="tcg fw-600 id-label">Control Number: <label
                                        class="tcgray fw-500 id-label">{{controlNumber}}</label>
                                </label>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div *ngIf="policyNumber">
                                <label *ngIf="isCatalytic" class="tcg fw-600 id-label">
                                    <label>Contract Number: <label
                                            class="tcgray fw-500 id-label">{{policyNumber}}</label></label>
                                </label>

                                <label *ngIf="!isCatalytic" class="tcg fw-600 id-label">
                                    <label>Policy Number: <label
                                            class="tcgray fw-500 id-label">{{policyNumber}}</label></label>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--End Insured Data-->

        </div>

        <!--Files Section-->
        <div class="col-md-12">
            <div class="">
                <h4 class="tc tcgray fw-500 fs-20">
                    Your underwriter requires the following subjectivities for this account.
                </h4>
            </div>

            <!--Required-->
            <section class="section-custom" *ngIf="filesRequired.length > 0 || inspectionContacLocation === 'Required'">
                <div class="border border-radius ptb">
                    <div class="container">
                        <h4 class="tcg title-section">Required <fa-icon placement="top"
                                ngbTooltip="Attach and Sign files for the Required Section" class="tcg icon-fs-medium"
                                [icon]="faInfoCircleIcon">
                            </fa-icon>
                        </h4>
                        <div class="container">
                            <!--Inspection Contact-->
                            <div class="row" *ngIf="isWebForm && inspectionContacLocation === 'Required'">
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-1 ic-condition">
                                            <fa-icon class="tcg is-condition icon-opacity" [icon]="faUserIcon">
                                            </fa-icon>
                                        </div>
                                        <div class="col-md-11 c-condition">
                                            <h4 class="text-condition">Inspection Contact</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 tar">
                                    <!--Edit Details-->
                                    <button *ngIf="!isWebFormDone" class="btn btn-custom bcg tcw"
                                        (click)="openDialog()">Edit Details
                                        <fa-icon class="tcw icon-fs-medium pl-icon" [icon]="faEditIcon"></fa-icon>
                                    </button>

                                    <label class="tco io fw-600 fs-16" *ngIf="isWebFormDone">Completed
                                        <fa-icon class="tco fs-18 pl-icon" [icon]="faCheckCircleIcon"></fa-icon></label>
                                </div>
                            </div>
                            <!-- Documents -->
                            <div>
                                <h4 class="tco subtitle-section io">Sign Files</h4>
                                <app-documents (refreshData)="refreshData($event)" [filesArray]="filesRequired" [isSupported]="isSupported"></app-documents>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!--Pre-Bind-->
            <section class="section-custom"
                *ngIf="filesPreBindSignature.length > 0 || filesPreBindAttach.length > 0 || inspectionContacLocation === 'Pre-Bind'">
                <div class="border border-radius ptb">
                    <div class="container">
                        <h4 class="tcg title-section">Pre-Bind <fa-icon class="tcg icon-fs-medium"
                                [icon]="faInfoCircleIcon" placement="top"
                                ngbTooltip="Attach and Sign files for the Pre-Bind Binder and Fill the Contact for Inspection Form">
                            </fa-icon>
                        </h4>
                        <div class="container">
                            <!--Inspection Contact-->
                            <div class="row" *ngIf="isWebForm && inspectionContacLocation === 'Pre-Bind'">
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-1 ic-condition">
                                            <fa-icon class="tcg is-condition icon-opacity" [icon]="faUserIcon">
                                            </fa-icon>
                                        </div>
                                        <div class="col-md-11 c-condition">
                                            <h4 class="text-condition">Inspection Contact</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 tar">
                                    <!--Edit Details-->
                                    <button *ngIf="!isWebFormDone" class="btn btn-custom bcg tcw"
                                        (click)="openDialog()">Edit Details
                                        <fa-icon class="tcw icon-fs-medium pl-icon" [icon]="faEditIcon"></fa-icon>
                                    </button>

                                    <label class="tco io fw-600 fs-16" *ngIf="isWebFormDone">Completed
                                        <fa-icon class="tco fs-18 pl-icon" [icon]="faCheckCircleIcon"></fa-icon></label>
                                </div>
                            </div>

                            <div *ngIf="filesPreBindAttach.length > 0">
                                <h4 class="tco subtitle-section io">
                                    Attach Files</h4>
                                <app-documents [filesArray]="filesPreBindAttach" [isSupported]="isSupported">
                                </app-documents>
                            </div>

                            <div *ngIf="filesPreBindSignature.length > 0">
                                <h4 class="tco subtitle-section io">Sign Files
                                </h4>
                                <app-documents (refreshData)="refreshData($event)" [filesArray]="filesPreBindSignature" [isSupported]="isSupported">
                                </app-documents>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <!--Pre-Issuance-->
            <section class="section-custom"
                *ngIf="filesPreIssuanceAttach.length > 0 || filesPreIssuanceSignature.length > 0 || inspectionContacLocation === 'Pre-Issuance'">
                <div class="border border-radius ptb">
                    <div class="container">
                        <h4 class="tcg title-section">Pre-Issuance <fa-icon class="tcg icon-fs-medium"
                                [icon]="faInfoCircleIcon" placement="top"
                                ngbTooltip="Attach and Sign files for the Pre-Issuance Section">
                            </fa-icon>
                        </h4>
                        <div class="container">
                            <!--Inspection Contact-->
                            <div class="row" *ngIf="isWebForm && inspectionContacLocation === 'Pre-Issuance'">
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-1 ic-condition">
                                            <fa-icon class="tcg is-condition icon-opacity" [icon]="faUserIcon">
                                            </fa-icon>
                                        </div>
                                        <div class="col-md-11 c-condition">
                                            <h4 class="text-condition">Inspection Contact</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 tar">
                                    <!--Edit Details-->
                                    <button *ngIf="!isWebFormDone" class="btn btn-custom bcg tcw"
                                        (click)="openDialog()">Edit Details
                                        <fa-icon class="tcw icon-fs-medium pl-icon" [icon]="faEditIcon"></fa-icon>
                                    </button>

                                    <label class="tco io fw-600 fs-16" *ngIf="isWebFormDone">Completed
                                        <fa-icon class="tco fs-18 pl-icon" [icon]="faCheckCircleIcon"></fa-icon></label>
                                </div>
                            </div>
                            <!-- Documents -->
                            <div *ngIf="filesPreIssuanceAttach.length > 0">
                                <h4 class="tco subtitle-section io">Attach Files
                                </h4>
                                <app-documents [filesArray]="filesPreIssuanceAttach" [isSupported]="isSupported">
                                </app-documents>
                            </div>

                            <div *ngIf="filesPreIssuanceSignature.length > 0">
                                <h4 class="tco subtitle-section io">Sign Files
                                </h4>
                                <app-documents (refreshData)="refreshData($event)" [filesArray]="filesPreIssuanceSignature" [isSupported]="isSupported">
                                </app-documents>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!--Policy Documnents-->
            <section *ngIf="!isEmptyAccount">
                <div class="border border-radius ptb">
                    <div class="container">
                        <h4 class="tcg title-section">Policy Documents <fa-icon class="tcg icon-fs-medium"
                                placement="top" ngbTooltip="Policy Documents">
                            </fa-icon>
                        </h4>
                        <div class="container">
                            <div class="row mb-1em" *ngFor="let p of filesPolicy; last as isLast">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-1 ic-condition-policy">
                                            <fa-icon class="tcg icon-opacity is-condition" [icon]="faFileDownloadIcon">
                                            </fa-icon>
                                        </div>
                                        <div class="col-md-10">
                                            <h4 class="text-condition c-condition">{{p.title}}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 tar">

                                    <!--In Process-->
                                    <label *ngIf="!p.isDocumentGenerated && !p.isDocumentReady" class="tcgray pt-7">In
                                        Process</label>

                                    <!--Document Available-->
                                    <button *ngIf="p.isDocumentGenerated && !p.isDocumentReady"
                                        class="btn btn-policies-available tcw pt-7" placemetn="top"
                                        ngbTooltip="You must submit required subjectivities to download document.">Document
                                        Available</button>

                                    <!--Document To Download-->
                                    <button *ngIf="p.isDocumentGenerated && p.isDocumentReady" [disabled]="false"
                                        class="btn btn-policies-download tcw pt-7 br-8"
                                        (click)="downloadPolicies(p.type)">
                                        <fa-icon class="tcw icon-fs-medium icon-opacity" [icon]="faFileDownloadIcon">
                                        </fa-icon> {{p.downloadText}}
                                    </button>
                                </div>
                                <hr *ngIf="!isLast" class="hr-custom">
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    </div>

    <!--Footer-->
    <div class="container">
        <div class="row bcg border-radius footer">
            <div class="col-md-12">
                <div class="tc">
                    <label class="tcw">The file extensions below are acceptable for uploading</label>
                </div>
                <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-2" *ngFor="let f of fileIconArray; let i = index">
                                <fa-icon class="tcw display-block" [icon]="f.fileIcon"></fa-icon>
                                <label class="tcw font-12">{{f.fileTitle}}</label>
                            </div>
                            <div class="col-md-1"></div>
                        </div>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-12 tc">
                        <label class="tcw">20 MB limit for each file</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

<div class="div-notSopporting" *ngIf="!this.isSupported && !this.isIE">
    <div class="alert alert-warning m-auto" role="alert">
        This web site is best viewed with these compatible browsers <a href="https://www.google.com/chrome/">Download
            browser</a>
    </div>
</div>