import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { InsuredDataService } from 'src/app/Services/insured-data.service';

@Component({
  selector: 'app-dialog-dw-file',
  templateUrl: './dialog-dw-file.component.html',
  styleUrls: ['./dialog-dw-file.component.scss']
})
export class DialogDwFileComponent implements OnInit {

  formGroup = new UntypedFormGroup({
    firstName: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$'
    )])
  });

  constructor(
    public dialogRef: MatDialogRef<DialogDwFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private insuredSrvc: InsuredDataService
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.insuredSrvc.GetLogDocumentDownloaded(this.data.quoteGuid).subscribe((resp:any) => {
      console.log(resp);
      if(resp.body){
        this.formGroup.patchValue({
          firstName: resp.body.name,
          email: resp.body.emailAddress
        });
      }

    });
    
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  sendButton() {
    if (this.formGroup.valid) {
      let name = this.formGroup.value.firstName;
      let email = this.formGroup.value.email

      this.dialogRef.close({ name, email });
    }
  }

}
