import { Component, Inject } from "@angular/core";
import { UntypedFormGroup, Validators, UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as IMask from "imask";
import { InsuredDataService } from "src/app/Services/insured-data.service";

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent {
  formGroup = new UntypedFormGroup({
    firstName: new UntypedFormControl("", [this.NoWhitespaceValidator]),
    email: new UntypedFormControl("", [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"),
    ]),
    phoneNumber: new UntypedFormControl("", [
      Validators.required,
      Validators.minLength(12),
    ]),
  });

  isOneorAnother: any;
  isFirstOrLastEmpty: any;

  phoneMask = IMask.createMask({
    mask: "000-000-0000",
  });

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private insuredSrvc: InsuredDataService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  inspectionButton() {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      this.sendData();
      this.onNoClick();
    }
  }

  sendData() {
    const arrayName: string[] = this.formGroup.value.firstName.split(" ");
    let firstName = "";
    let lastName = "";

    for (let i = 0; i < arrayName.length; i++) {
      if (i > 0) {
        lastName += arrayName[i] + " ";
      } else {
        firstName = arrayName[0];
      }
    }

    const formData = new FormData();
    formData.append("magicLinkId", this.data.insuredData.magicLinkId);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", this.formGroup.value.email);
    formData.append("phoneNumber", this.formGroup.value.phoneNumber);
    formData.append("ip", this.data.ip);
    formData.append("conditionId", this.data.conditionId);

    this.insuredSrvc.postInspectionContactData(formData).subscribe(
      (response) => this.reloadPage(),
      (error) => this.reloadPage()
    );
  }

  reloadPage() {
    setTimeout(() => {
      location.reload();
    }, 1000);
  }

  NoWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { required: true };
  }
}
