import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  API_URL: any = environment.EnvironmentLSA;
  TOKEN: any = environment.LSAToken;
  headersLSA = new HttpHeaders({
    Authorization: 'Bearer ' + this.TOKEN
  });

  constructor(private httpClient: HttpClient) { }

  sendFileToLSAAPI(formData: any) {
    return this.httpClient
      .post(
        this.API_URL + 'UploadDocSJToIMS',
        formData,
        {
          headers: this.headersLSA,
          reportProgress: false,
          observe: 'response'
        }
      )
  }

}
