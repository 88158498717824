import {
  Component,
  OnInit,
  Input,
  HostListener,
  EventEmitter,
  Output,
} from "@angular/core";
import {
  faInfoCircle,
  faFile,
  faPenNib,
  faPaperPlane,
  faPaperclip,
  faCheckCircle,
  faUser,
  faEdit,
  faUpload,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

// Swal
import Swal from "sweetalert2";
import { DocuSignService } from "src/app/Services/docu-sign.service";
import { ListDocumentsEmitterService } from "src/app/Services/list-documents-emitter.service";
import { IpService } from "src/app/Services/ip-service";
import { InsuredDataService } from "src/app/Services/insured-data.service";
import { UploadFileService } from "src/app/Services/upload-file.service";
import { catchError } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";
import { ValidateTokenService } from "src/app/Services/validate-token.service";
import { UntypedFormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { DialogUploadFileComponent } from "src/app/shared/dialog-upload-file/dialog-upload-file.component";
import { DataUploadFile } from "src/app/shared/interfaces/dataUploadFile";
import { LogService, Level } from "src/app/Services/log.service";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.scss"],
})
export class DocumentsComponent implements OnInit {
  constructor(
    private docuSignSrvc: DocuSignService,
    private eventEmttrSrvc: ListDocumentsEmitterService,
    private ipService: IpService,
    private insuredDataService: InsuredDataService,
    private uplodafileSrvc: UploadFileService,
    private tkService: ValidateTokenService,
    private insuredSrvc: InsuredDataService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private logService: LogService
  ) {}

  // Input
  @Input() filesArray: any[];
  @Input() insuredDataEmmited: any;
  @Input() isSupported: boolean;
  @Output() refreshData = new EventEmitter<string>();

  // Insured Data
  insuredData: any;
  ip: any;
  maxFileSize = 20480; // in Kilobyte (KB)

  // Section Variables
  sectionTitle: any = "";
  titleAction: any = "";
  actionButton: any = "";
  insuredEmail: any = "";
  magicLinkId: any = "";

  emailFormControl = new UntypedFormControl("", [
    Validators.required,
    Validators.email,
    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
  ]);

  isInsuredClick = false;
  isBrokerClick = false;

  // Icon
  faInfoCircleIcon: any = faInfoCircle;
  faFileIcon: any = faFile;
  faPenNibIcon: any = faPenNib;
  faPaperPlaneIcon: any = faPaperPlane;
  faPaperclipIcon: any = faPaperclip;
  faCheckCircleIcon: any = faCheckCircle;
  faUserIcon: any = faUser;
  faEditIcon: any = faEdit;
  faUploadIcon: any = faUpload;
  faTimesCircleIcon: any = faTimesCircle;

  attachFile = false;
  showSignSpinner: boolean = false;

  @HostListener("window:message", ["$event"])
  onMessage(e) {
    if (e.data === "CloseModal") {
      Swal.close();
    }
  }

  ngOnInit(): void {
    this.magicLinkId = this.activatedRoute.snapshot.params.magicLinkId;

    // Get Ip
    this.getIP();

    this.insuredData = this.eventEmttrSrvc.data;
  }

  getIP() {
    this.ipService.getIpAddress().subscribe((res: any) => {
      this.ip = res.ip;
    });
  }

  onSend(file: any, condition: any, conditionId: any, templateId: any) {
    if (
      this.insuredData.insuredEmail === null ||
      this.insuredData.insuredEmail === ""
    ) {
      // this.sweetAlert('Email is required', 'warning');
      this.sweetModalEmail("Enter insured email", "info");
    }

    if (this.checkSessionTime() && this.insuredData.insuredEmail) {
      // Change Send Insured Button to Disabled
      this.isInsuredClick = true;

      const formData = new FormData();
      formData.append("inputDocument", file.fileContents);
      formData.append("fileName", condition);
      formData.append("signerName", this.insuredData.insuredName);
      formData.append("signerEmail", this.insuredData.insuredEmail);
      formData.append("conditionID", conditionId);
      formData.append("templateId", templateId);
      formData.append("quoteGuid", this.insuredData.quoteGuid);
      formData.append("company", this.insuredData.company);

      this.docuSignSrvc
        .postInsuredDocument(formData)
        .subscribe((response: any) => {
          const formData2 = new FormData();

          formData2.append("docuSignDocumentGuid", response.body.EnvelopeId);
          formData2.append("envelopeId", "1");
          formData2.append("conditionId", conditionId);
          formData2.append("quoteGuid", this.insuredData.quoteGuid);
          formData2.append("status", response.body.Status);

          // InsertDocumentDocusignSP
          this.insuredDataService.postDocuSignData(formData2).subscribe(
            (response2: any) => {},
            (error) => {}
          );

          setTimeout(() => {
            location.reload();
          }, 1000);

          this.sweetAlert("Email sent", "success");
        });
    }
  }

  onReSend(file: any, condition: any, conditionId: any, templateId: any) {
    Swal.fire({
      title: "Enter insured email",
      input: "email",
      inputPlaceholder: "insured@email.com",
      inputValue: this.insuredData.insuredEmail,
      inputValidator: (value) => {
        return !value && "Email is required";
      },
      icon: "info",
    }).then((response) => {
      if (response?.value) {
        this.insuredEmail = response?.value;
        this.emailFormControl.setValue(this.insuredEmail);
        this.updateInsuredEmail();

        const formData = new FormData();
        formData.append("inputDocument", file.fileContents);
        formData.append("fileName", condition);
        formData.append("signerName", this.insuredData.insuredName);
        formData.append("signerEmail", this.insuredEmail);
        formData.append("conditionID", conditionId);
        formData.append("templateId", templateId);
        formData.append("quoteGuid", this.insuredData.quoteGuid);
        formData.append("company", this.insuredData.company);

        this.docuSignSrvc.postInsuredDocument(formData).subscribe(() => {
          setTimeout(() => {
            location.reload();
          }, 1000);

          this.sweetAlert("Email sent", "success");
        });
      }
    });
  }

  onSign(
    file: any,
    fileName: any,
    returnUrl: any,
    conditionId: any,
    templateId: any,
    conditionStatus: string
  ) {
    this.showSignSpinner = true;
    if (this.checkSessionTime()) {
      Swal.fire({
        title: "Email Address To Receive Signed Application",
        input: "email",
        inputPlaceholder: "Email Address",
        showCloseButton: true,
        inputValidator: (value) => {
          if (!value || value.trim() == "") {
            return "Email is required";
          }

          if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) == false) {
            return "Invalid email format";
          }
        },
      }).then((response) => {
        if (response?.value) {
          const email = response.value ?? "";
          this.showDocuSignPopup(
            file,
            fileName,
            returnUrl,
            conditionId,
            templateId,
            email,
            conditionStatus
          );
          return;
        }

        if (response?.dismiss) {
          this.showSignSpinner = false;
        }
      });
    }
  }

  showDocuSignPopup(
    file: any,
    fileName: any,
    returnUrl: any,
    conditionId: any,
    templateId: any,
    email,
    conditionStatus: string
  ) {
    this.isBrokerClick = true;
    returnUrl = environment.returnURLDocusign;

    const completedSignData = {
      insuredData: this.insuredData,
      fileName,
      magicLinkId: this.insuredData.magicLinkId,
      conditionId,
      ip: this.ip,
    };

    const formData = new FormData();

    formData.append("inputDocument", file);
    formData.append("fileName", fileName);
    formData.append("returnUrl", returnUrl);
    formData.append(
      "signerClientId",
      (this.insuredData.magicLinkId + conditionId).toString()
    );
    formData.append("conditionID", conditionId);
    formData.append("quoteGuid", this.insuredData.quoteGuid);
    formData.append("templateId", templateId);
    formData.append("company", this.insuredData.company);
    formData.append("emailInsured", email);

    this.docuSignSrvc
      .postBrokerDocument(formData)
      .subscribe((response: any) => {
        const formData2 = new FormData();

        formData2.append("docuSignDocumentGuid", response.body.evelopeId);
        formData2.append("envelopeId", "1");
        formData2.append("conditionId", conditionId);
        formData2.append("quoteGuid", this.insuredData.quoteGuid);
        formData2.append("status", "sent");

        // InsertDocumentDocusignSP
        this.insuredDataService.postDocuSignData(formData2).subscribe(
          (response2: any) => {
            debugger;
          },
          (error) => {}
        );

        Swal.fire({
          html: `<div class="iframe-container">
          <iframe allowfullscreen sandbox="allow-same-origin allow-top-navigation allow-scripts allow-popups allow-pointer-lock" src="${response.body.url.Url}">
          </iframe></div>`,
          showCancelButton: false,
          allowEnterKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          cancelButtonText: "Close",
          customClass: {
            popup: "swal2-broker",
            cancelButton: "cancel-btn-doc",
          },
        }).then((result) => {
          this.refreshData.emit(conditionStatus);
        });

        localStorage.setItem(
          "completedSignData",
          JSON.stringify(completedSignData)
        );
      });
  }

  onSelect(event: any, index: any, docName: any, conditionId: any) {
    if (event.addedFiles.length > 0) {
      // it is validated that it does not exceed
      if (event.addedFiles[0].size / 1024 < this.maxFileSize) {
        this.filesArray[index].isAttached = false;
        this.filesArray[index].file = event.addedFiles[0];
      } else {
        this.sweetAlert("File exceeds max size", "error");
      }
    } else {
      if (!this.filesArray[index]) {
        this.sweetAlert("Not file was added", "warning");
      }
    }
  }

  uploadFile(
    file: any,
    index: any,
    fileName: any,
    conditionId: any,
    fileDescription: any,
    documentTypeGuid: any,
    folderId: any,
    folderName: any
  ) {
    if (this.checkSessionTime()) {
      if (file !== null || file !== undefined) {
        const formData = new FormData();
        formData.append("QuoteGuid", this.insuredData.quoteGuid);
        formData.append("controlNo", this.insuredData.controlNumber);
        formData.append("fileName", fileName);
        formData.append("fileData", file);
        formData.append("magicLinkId", this.insuredData.magicLinkId);
        formData.append("fileConditionId", conditionId);
        formData.append("ip", this.ip);
        formData.append("fileDescription", fileDescription);
        if (documentTypeGuid) {
          formData.append("documentTypeGuid", documentTypeGuid);
        }
        formData.append("folderId", folderId);
        formData.append("folderName", folderName);

        this.uplodafileSrvc
          .sendFileToLSAAPI(formData)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              return of("Upload Failed" + error);
            })
          )
          .subscribe((response: any) => {
            if (response !== undefined) {
              // Needs to change the value when file isDone
              if (response.body !== undefined && response.body !== null) {
                this.sweetAlert("File Uploaded Succesfully", "success");
              }
              setTimeout(() => {
                location.reload();
              }, 1000);
            }
          });
      }
    }
  }

  removeFile(conditionId: any, index: any) {
    this.filesArray[index].isAttached = true;
  }

  updateInsuredEmail() {
    this.magicLinkId = this.activatedRoute.snapshot.params.magicLinkId;
    this.insuredEmail = this.emailFormControl.value;

    const formData = new FormData();
    formData.append("magicLinkId", this.magicLinkId);
    formData.append("insuredEmail", this.insuredEmail);

    this.insuredSrvc.updateInsuredEmail(formData).subscribe((response) => {
      this.sweetAlert("Email Updated", "success");

      setTimeout(() => {
        location.reload();
      }, 1000);
    });
  }

  sweetAlert(message: any, icon: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
    });

    Toast.fire({
      icon,
      title: message,
      customClass: {
        container: "toast-custom",
        icon: "toast-icon",
        title: "toast-title",
      },
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  }

  async sweetModalEmail(title: any, icon: any) {
    const { value: email } = await Swal.fire({
      title,
      input: "email",
      inputPlaceholder: "insured@email.com",
      inputValidator: (value) => {
        return !value && "Email is required";
      },
      icon,
    });
    if (email) {
      this.insuredEmail = email;
      this.emailFormControl.setValue(this.insuredEmail);
      this.updateInsuredEmail();
    }
  }

  checkSessionTime(): any {
    const token = sessionStorage.getItem("token");
    const formData = new FormData();
    formData.append("token", token);
    formData.append("magicLinkId", this.magicLinkId);

    return this.tkService.validateToken(formData).subscribe((response) => {
      if (response.body === "SessionCorrect") {
        return true;
      }
      return false;
    });
  }

  DowloadFunction(file, nameFileDowload) {
    if (this.isSupported) {
      const link = document.createElement("a");
      const fileBlob = this.converBase64toBlob(
        file.fileContents,
        "application/pdf"
      );
      link.download = nameFileDowload;
      const url = window.URL.createObjectURL(fileBlob);
      link.href = url;
      link.click();
    } else {
      const fileBlob = this.converBase64toBlob(
        file.fileContents,
        "application/pdf"
      );
      const url = window.URL.createObjectURL(fileBlob);
      try {
        window.open(url);
      } catch (err) {
        this.logService.Log('Subjectivity App: Error File Download', Level.ERROR);
      }
    }
  }

  converBase64toBlob(content, contentType) {
    contentType = contentType || "";
    const sliceSize = 512;
    const byteCharacters = window.atob(content); // method which converts base64 to binary
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: contentType,
    }); // statement which creates the blob
    return blob;
  }

  openDialogUploadFile(f: any) {
    const dataDocument: DataUploadFile = {
      conditionId: f.conditionID,
      controlNumber: this.insuredData.controlNumber,
      fileDescription: f.fileDescription,
      fileName: f.fileName,
      folderId: f.folderId,
      folderName: f.folderName,
      ip: this.ip,
      magicLinkId: this.magicLinkId,
      quoteGuid: this.insuredData.quoteGuid,
      documentTypeGuid: f.documentTypeGuid,
    };
    const dialogRef = this.dialog.open(DialogUploadFileComponent, {
      disableClose: true,
      hasBackdrop: true,
      panelClass:'dialog-upload-file',
      width:'526px',
      data: dataDocument,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        setTimeout(() => {
          location.reload();
        }, 1000);
      }
    });
  }
}
