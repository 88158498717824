import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Components
import { LoginComponent } from './Pages/login/login.component';
import { InsuredComponent } from './Pages/insured/insured.component';
import { NotFoundComponent } from './Pages/not-found/not-found.component';
import { CheckAccessGuard } from './Guards/check-access.guard';
import { CompletedSignComponent } from './Pages/insured/Components/completed-sign/completed-sign.component';
import { BrowserNotSupportComponent } from './Pages/browser-not-support/browser-not-support.component';


const routes: Routes = [
  {
    path: 'BrowserNotSupport',
    component: BrowserNotSupportComponent
  },
  {
    path: 'Login/:magicLinkId',
    component: LoginComponent
  },
  {
    path: 'NotFound',
    component: NotFoundComponent
  },
  {
    path: 'Completed/:guid',
    component: CompletedSignComponent
  },
  {
    path: ':magicLinkId',
    component: InsuredComponent,
    canActivate: [CheckAccessGuard]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/NotFound'
  },
  {
    path: '**',
    redirectTo: 'NotFound'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
