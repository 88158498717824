import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocuSignService {

  API_URL: any = environment.EnvironmentDocuSign;
  TOKEN: any = environment.DocuSignToken;
  headersDodcSign = new HttpHeaders({
    Authorization: 'Bearer ' + this.TOKEN
  });

  // API_URL: any = 'https://localhost:5001/api/';

  constructor(private httpClient: HttpClient) { }

  postBrokerDocument(formData: any) {
    return this.httpClient
      .post(
        this.API_URL + 'Broker',
        formData,
        {
          headers: this.headersDodcSign,
          reportProgress: false,
          observe: 'response'
        }
      );
  }

  postInsuredDocument(formData: any) {
    return this.httpClient
      .post(
        this.API_URL + 'Insured',
        formData,
        {
          headers: this.headersDodcSign,
          reportProgress: false,
          observe: 'response'
        }
      );
  }

  postGuidDocument(guid: any) {
    return this.httpClient
      .post(
        this.API_URL + 'Document/' + guid,
        '',
        {
          headers: this.headersDodcSign,
          reportProgress: false,
          observe: 'response'
        }
      );
  }
}
