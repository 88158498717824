<form [formGroup]="formGroup" (ngSubmit)="inspectionButton()">
  <h2 class="tcg tc">Inspection Contact Data</h2>
  <h5 class="tcg tc">
    *Additional contact information may be requested by underwriter
  </h5>
  <div class="tal mt-5">
    <h4 class="tcg tal mb-0">Name</h4>
    <input class="w100" type="text" formControlName="firstName" id="name" />
    <mat-error *ngIf="formGroup?.get('firstName')?.hasError('required')">
      Name is <strong>required</strong>
    </mat-error>
  </div>
  <div class="tal mt-5">
    <h4 class="tcg tal mb-0">Email</h4>
    <input class="w100" type="text" formControlName="email" id="email" />
    <mat-error *ngIf="formGroup?.get('email')?.hasError('pattern')">
      Must be an <strong>email</strong>
    </mat-error>
    <mat-error *ngIf="formGroup?.get('email')?.hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
  </div>

  <div class="tal mt-5">
    <h4 class="tcg tal mb-0">Phone</h4>
    <input
      class="w100"
      type="text"
      formControlName="phoneNumber"
      id="phone"
      [imask]="phoneMask"
      [unmask]="false"
    />
    <mat-error *ngIf="formGroup?.get('phoneNumber')?.hasError('required')">
      Phone is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="formGroup?.get('phoneNumber')?.hasError('minlength')">
      Must be a <strong>phone</strong>
    </mat-error>
  </div>

  <button class="btn bcg btn-dialog-custom tcw">Submit</button>
</form>
