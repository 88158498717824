<form [formGroup]="formGroup" (ngSubmit)="sendButton()">
    <h2 class="tcg tc">Document Download Request</h2>
    <div class="tal mt-5">
        <h4 class="tcg tal mb-0">Name</h4>
        <input class="w100" type="text" formControlName="firstName" id="name">
        <mat-error
            *ngIf="formGroup.controls.firstName.hasError('required') && formGroup.controls.firstName.value === ''">
            Name is <strong>required</strong>
        </mat-error>
    </div>
    <div class="tal mt-5">
        <h4 class="tcg tal mb-0">Email</h4>
        <input class="w100" type="text" formControlName="email" id="email">
        <mat-error *ngIf="formGroup.controls.email.hasError('email')">
            Must be an <strong>email</strong>
        </mat-error>
    </div>

    <button class="btn bcg btn-dialog-custom tcw">Submit</button>
</form>